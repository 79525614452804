<div class = "grid grid-cols-12" (window:resize)="resizeWindow()">
	<div
		*ngIf="!isLoading && service else loading"
		class="relative col-span-12 lg:col-span-4"
	>
		<div
			[@OrderTab] = "isModalOpen? 'open':'close'"
			class = "
        grid
        grid-rows-6
        grid-flow-col
        overflow-hidden
        h-screen
        w-full
        absolute
        z-50
        shadow
        bg-gray-50
		
    "
	
		>
			<div class = "grid grid-cols-3 row-span-1 p-4 ">
				<div class = "flex justify-start">
					<app-icon-button
						(click) = "onNavigateBack()"
						[icon] = "icon"
						[label] = "'actions.back' | translate"
						hover = "hover:text-primary-500"
						textColor = "text-secondary-500"
					></app-icon-button>
				</div>
				<div class = "flex col-start-3 justify-end invisible lg:visible">
					<app-icon-button
						(click) = "handleSideTab()"
						[icon] = "faWindowMinimize"
						hover = "hover:text-primary-500"
						textColor = "text-secondary-500"
					></app-icon-button>
				</div>
				<div class ="col-span-3">
          <div class="grid grid-cols-3 ">
            <div  *ngIf="service.title.length <= 66" class="col-span-3">
            	<h1 class = "font-bold text-2xl text-secondary-500 ">{{service.title}}</h1>
            </div>
			<div  *ngIf="service.title.length > 66" class="col-span-3">
				<h1 class = "md:text-base font-bold 2xl:text-xl text-secondary-500">{{service.title}}</h1>
			</div>
            <div *ngIf="service.isTryAndBuy" class="col-span-1 justify-self-end">
              <button
                type="button"
                (click)="onTryAndBuy()"
                class="inline-flex items-center py-2.5 px-4 text-sm font-medium uppercase text-secondary-500 hover:text-white rounded outline focus:ring-4 focus:ring-primary-200 hover:bg-secondary-600"
              >
                <fa-icon [icon]="faCartShopping"></fa-icon>
                <p class="ml-2">{{ 'actions.try-service' | translate }}</p>
              </button>
            </div>
          </div>
				</div>
			</div>
			<div class="row-span-5 overflow-y-scroll overflow-x-hidden  p-4">
				<div class="grid grid-cols-{{ tabs.length }} gap-4">
					<button
						(click)="activeTab = tab.id"
						*ngFor="let tab of tabs"
						[ngClass]="activeTabClass(tab.id)"
						class="uppercase duration-300 hover:border-primary-500 border-b-2 text-sm break-words"
					>
						{{ tab.title | translate}}
					</button>
					
				</div>
				<div class=" z-0 w-full mt-5 " *ngIf="isMobile==true">
					<app-map
						class="{{isModalOpen? 'col-span-12': 'col-span-12'}}"
						[multiFootprint]="hasMultipleFootprints"
						[numberOfFootprints]="numberOfFootprints"
						(changeFootprintFocus)="onFootprintChange($event)"
					></app-map>
				</div>
				<ng-container>
          <div class="mx-4 mt-4">
            <app-service-details
              *ngIf="activeTab === 'details'"
              [created]="service.createdAt"
              [description]="service.description"
			  [unitPrice]="service.unitPrice"
			  [measureUnitsArea]="'main-page.orders.measureUnits.area.' + service.measureUnits?.area  | translate"
			  [measureUnitsTime]="'main-page.orders.measureUnits.time.' + service.measureUnits?.time | translate"
			  [customRequestPrice]="service.customRequestPrice"
              [documentationURI]="service.documentationURI"
              [keywords]="service.keywords"
              [samplesURIs]="service.sampleURIs"
              [legalConstraints]="service.legalConstraints"
              [provider]="service.serviceProvider"
              [type]="service.serviceType"
              [versionNumber]="service.versionNumber"
			  [orderFee]="service.orderFee"
			  [aioScale]="service.aoiScale"
			  [eulaUri]="service.eulaUri"
            ></app-service-details>
			<app-service-products-specification
			*ngIf="activeTab === 'products-specification'"
			[outputInformation] = "service.outputInformation"
			></app-service-products-specification>
            <app-service-instructions
              *ngIf="activeTab === 'instructions'"
			  [instructions]= "service.serviceInstructions"
            ></app-service-instructions>
            <app-make-order
              [isOrderLoading]="isOrderLoading"
              (data)="onReceiveData($event)"
              [ngStyle]="{'display': activeTab !== 'order' ? 'none' : 'inline'}"
              [geoRecordIdentifier]="id"
              [geoRecordSubType]="service.serviceSubtype"
              [geoRecordType]="service.serviceType"
              [serviceFootprint]="service.serviceFootprint"
              [servicePeriod]="service.servicePeriod"
              [service]="service"
			  [ordersDisabled]="!hasOrderPermission || !hasOrderAccess || !isLoggedIn"
            >
            </app-make-order>
			
          </div>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="z-0 w-full absolute" *ngIf="isMobile==false">
		<app-map
			class = "z-40 {{isModalOpen? 'col-span-12': 'col-span-12'}}"
			id="service-map"
			[multiFootprint]="hasMultipleFootprints"
			[numberOfFootprints]="numberOfFootprints"
			(changeFootprintFocus)="onFootprintChange($event)"
		></app-map>
	</div>
</div>
<ng-template #loading>
	<div class="z-50 relative col-span-12">
		<div
			class = "
        grid
        grid-cols-4
        col-span-4
        place-content-center
        h-screen
        z-50
        shadow
        bg-gray-50
        gap-4
        absolute
        w-full
        p-4
    "
		>
			<p class = "col-span-4 place-self-center text-gray-500">{{'actions.loading.page-content'|translate}}</p>
			<div class = "col-span-2 p-2 bg-gray-300 rounded"></div>
			<div class = "col-span-4 p-2 bg-gray-300 rounded"></div>
			<div class = "flex justify-between col-span-4 gap-4">
				<div
					*ngFor = "let tab of tabs"
					class = " flex-1 border-b-2"
				>
					<div class = "p-2 bg-gray-300 rounded mb-2"></div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<div class = "absolute z-50 bg-secondary-500 text-gray-50 rounded-r top-12 {{isModalOpen?'hidden':'visible'}}">
	<app-icon-button
		(btnClick) = "isModalOpen=true"
		[icon] = "faWindowRestore"
		hover = "hover:text-primary-500 duration-300"
	></app-icon-button>
</div>

<app-alert-window
	*ngIf="activeTab === 'order' && hasOrderAccess && !hasOrderPermission"
	[title]="'alert-dialog.commercialPermissionPending.title' | translate"
	[textContent]="'alert-dialog.commercialPermissionPending.message' | translate"
	[confirmLabel]="'alert-dialog.commercialPermissionPending.action' | translate"
	(confirmBtnClick)="onDialogConfirm()"
></app-alert-window>

<app-alert-window
	*ngIf="activeTab === 'order' && !hasOrderAccess"
	[title]="'alert-dialog.userOrdersNotAllowed.title' | translate"
	[textContent]="'alert-dialog.userOrdersNotAllowed.message' | translate"
	[confirmLabel]="'alert-dialog.userOrdersNotAllowed.action' | translate"
	(confirmBtnClick)="onDialogConfirm()"
></app-alert-window>

<app-alert-window
	*ngIf="activeTab === 'order' && !isLoggedIn"
	[title]="'alert-dialog.withoutUserOrdersNotAllowed.title' | translate"
	[textContent]="'alert-dialog.withoutUserOrdersNotAllowed.message' | translate"
	[confirmLabel]="'alert-dialog.withoutUserOrdersNotAllowed.action' | translate"
	[loginLabel]="'auth.sign-in' | translate"
	(confirmBtnLogin)="onDialogConfirmLogin()"
	(confirmBtnClick)="onDialogConfirm()"
	[hasLogginBtn]="true"
></app-alert-window>

