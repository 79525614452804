import {
  HttpClient,
  HttpClientModule, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  BrowserModule
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiInterceptor, AuthInterceptor, langId, LoadingInterceptor, TranslateService } from '@core/';
import { AuthConfigModule } from '@core/auth/auth-config.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthModule } from 'angular-auth-oidc-client';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from 'app-routing.module';
import { AppComponent } from 'app.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from 'shared';
import { environment } from '../environments';
import { LottieModule } from 'ngx-lottie';

import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: (environment.production || environment.staging) ? 'services4eo.com' : 'localhost'
  },
  palette: {
    popup: {
      background: '#1b775d'
    },
    button: {
      background: '#258a8f'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
};

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthConfigModule,
    AuthModule,
    NgxPermissionsModule.forRoot(),
    FontAwesomeModule,
    SharedModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      defaultLanguage: langId,
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
      },
    }),
    LottieModule.forRoot({ player: playerFactory }),
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsID)
  ],
  providers: [
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

