import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AnalyticsService,
    INewOrder,
    MapService,
    NavigationService,
    OrdersServices, Service, ServicesService,
    SnackbarService,
    TranslateService
} from '@core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronLeft, faEyeSlash, faMap, faTimes, faWindowRestore, faCartShopping
} from '@fortawesome/free-solid-svg-icons';
import { MultiPolygon } from '@turf/turf';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { parseFromWK } from 'wkt-parser-helper';

type TabId = 'details' | 'products-specification' |'instructions' | 'order' | 'price-model';

interface Tab {
  id: TabId; 
  title: string;
}

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
  animations: [
    trigger('OrderTab', [
      state('open', style({
        transform: 'translateX(0%)'
      })),
      state('close', style({
        transform: 'translateX(-100%)'
      })),
      transition('close => open', [
        query(':self', style({transform: 'translateX(-100%)'})),
        query(':self',
            animate('100ms ease-out', style({transform: 'translateX(0%)'}))),
      ]),
      transition('open => close', [
        query(':self', style({transform: 'translateX(0%)'})),
        query(':self', animate('100ms ease-in', style({transform: 'translateX(-100%)'}))),
      ])
    ]),
  ],
})
export class BuyComponent implements OnInit, OnDestroy {
  
  isOrderLoading: boolean = false;
  isMobile: boolean = true;

  tabs: Tab[] = [
    {
      id: 'details',
      title: 'main-page.orders.details',
    },
    {
      id: 'instructions',
      title: 'main-page.orders.instructions',
    },
    {
      id: 'order',
      title: 'main-page.orders.order',
    },
  ];

  componentDestroyed$: Subject<boolean> = new Subject();
  id: string = '';
  activeTab: TabId = 'details';
  service!: Service;
  icon: IconDefinition = faChevronLeft;
  closeIcon: IconDefinition = faTimes;
  isModalOpen: boolean = true;
  faMap: IconDefinition = faMap;
  faWindowRestore: IconDefinition = faWindowRestore
  faWindowMinimize: IconDefinition = faEyeSlash
  faCartShopping: IconDefinition = faCartShopping
  
  footprintGeoJSON: any;
 
  hasOrderPermission: boolean = true;
  hasOrderAccess: boolean = true;
  isLoading: boolean = false;
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mapService: MapService,
    private serviceServices: ServicesService,
    private orderServices: OrdersServices,
    private snackbarService: SnackbarService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    public oidcSecurityService: OidcSecurityService,
    private analyticsService: AnalyticsService
 
  ) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id') as string;
  }

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated().pipe(take(1)).subscribe({
      next: (authenticated)=>{

        this.isLoggedIn = authenticated;

      },error: (error) => {}
    });
     
    this.isLoading = true;
    this.getServiceByIdentifier();
    
    if(window.innerWidth <1024){
      
      this.isMobile=true;
      
    }
    else{
    
      this.isMobile=false;
      
    }

  }

  onNavigateBack() {
    this.navigationService.onNavigate();
    this.navigationService.back(`/customers/marketplace`);
  }

  getServiceByIdentifier(){
    this.serviceServices.getServiceByIdentifier(this.id).pipe(take(1)).subscribe({
      next: service => {
        this.service = service;

        this.analyticsService.emitServiceViewEvent(this.service.identifier, this.service.title);

        this.footprintGeoJSON = parseFromWK(this.service.serviceFootprint);
        this.checkUserPermission();
        this.serviceServices.getServicePeriod(this.service.identifier).pipe(take(1)).subscribe((period) => {
          this.service.servicePeriod = period;
          this.isLoading = false;
          if (this.service.outputInformation && Object.keys(this.service.outputInformation).length > 0) {
          
            const key = 'id';
    
            this.tabs.splice(1, 0, {
              id: 'products-specification',
              title: 'main-page.orders.products-specification'
            });
      
            const arrayUniqueByKey = [...new Map(this.tabs.map(item =>
              [item[key], item])).values()];
    
            this.tabs = arrayUniqueByKey;
                   
          }
          this.insertFootprintOnMap(100);
          this.mapService.drawingLayer$
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe();
          this.insertFootprintOnMap(0);
        });
        

      },
      error: error => {
        this.isLoading = false;
        this.router
            .navigate(['**'], {
            })
            .finally();
        // console.log(error.status);
      }
    })
  }


  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  makeOrder(order: INewOrder) {
    
    this.isOrderLoading = true;

    const orderType = order.isSubscription ? 'subscription' : 'regular_order'

    this.orderServices.createNewOrder(order).subscribe(
      {
        next: () => {
          this.analyticsService.emitOrderEvent(this.service.identifier, orderType);
          this.snackbarService
            .success(
              this.translateService.translate('alerts.title.success'),
              this.translateService.translate('alerts.description.success')
            )
            .during(4000)
            .show();
          this.router.navigate(['/customers/orders'], {
            relativeTo: this.activatedRoute.parent,
          });

          this.isOrderLoading = false
        },
        error: () => {
          this.isOrderLoading = false;
          this.analyticsService.emitFailedOrderEvent(this.service.identifier, orderType);
        },
      }
    );
  }

  onTryAndBuy() {
    this.service.tryAndBuyParameters.serviceId = this.id;
    this.makeOrder(this.service.tryAndBuyParameters);
  }


  insertFootprintOnMap(timeout: number) {
    setTimeout(() => {
      if (this.service?.serviceFootprint) {
        this.mapService.setFootprint(this.footprintGeoJSON).finally();
      }
    }, timeout);
  }

  activeTabClass(tabId: string) {
    return {'border-primary-500': this.activeTab === tabId};
  }

  onReceiveData(data: INewOrder) {
    this.makeOrder(data);
  }

  handleSideTab(){
    this.isModalOpen = !this.isModalOpen;
    
  }

  resizeWindow(){

    if(window.innerWidth <1024){
    
      this.isMobile=true;
      
    }
    else{
    
      this.isMobile=false;
      
    }
    
    this.isMobile = window.innerWidth < 1024;
    this.getServiceByIdentifier();
    
  
 }

 
 checkMeasure(value:string|null){
  if(value === null){
    return '';
  }
  else {
    return value;
  }
 }

 onDialogConfirm() {
  this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
 }

 onDialogConfirmLogin(){
  // this.analyticsService.emitButtonClickedEvent('order_page_login', 'login');
  this.oidcSecurityService.authorize();
 }

 checkUserPermission() {
  if(this.isLoggedIn){
    this.orderServices.checkCommercialUserPermission(this.id, this.service.legalConstraints.toString())
    .pipe(take(1)).subscribe({
      next: (response) => {
        this.hasOrderPermission = response.allowedOrder;
        this.hasOrderAccess = response.allowedAccess;
      },
      error: () => {
        this.hasOrderPermission = false;
        this.hasOrderAccess = false;
      }
    });
  }
 }

 onFootprintChange(index: number) {
  this.mapService.zoomToFootprintPart(index, this.footprintGeoJSON);
 }

 get hasMultipleFootprints(): boolean {
  if (this.service) {
    return this.footprintGeoJSON && this.footprintGeoJSON.type === 'MultiPolygon' && this.numberOfFootprints > 1;
  } else {
    return false;
  }
 }

 get numberOfFootprints(): number {
  if (this.service) {
    if (this.footprintGeoJSON && this.footprintGeoJSON.type === 'MultiPolygon') {
      return this.footprintGeoJSON.coordinates.length;
    } else {
      return 1;
    }
  } else {
    return 0;
  }
 }

}
