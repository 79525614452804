export const environment = {
  production: true,
  staging: false,
  apiUrl: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: '78ba7b1c-8577-4d3a-ad24-a103372494b5',
  scope: 'profile openid email user_name gsc4eo',
  geoServerUrl: 'https://webgeo2.hidrografico.pt/geoserver/ows',
  apiKey: '4de969a4-083d-48aa-aa22-27478ee8b28b',
  paypal_clientID: 'ASdJ0ZIiMTct1KdvO5DCrFZeyCGu61wz5ZnzfczHnBvU37R1hTfMFJrF2SvEeonFJB2mDSbAGfSv1Qw0',
  //? External links
  externalLinks: {
    deimos: 'https://elecnor-deimos.com',
    esa: 'https://www.esa.int',
    support: 'https://service4eo.atlassian.net/servicedesk/customer/portal/9',
    ipma: 'https://www.ipma.pt/en/index.html',
    cls: 'https://www.cls.fr/en/',
    air: 'https://www.aircentre.org/',
    nova: 'https://www.novasbe.unl.pt/en/',
    terra: 'https://www.terradue.com/portal/',
    sintef_ocean: 'https://www.sintef.no/sintef-ocean/',
    sintef_nord: 'https://www.sintef.no/en/',
    pml: 'https://pml.ac.uk/',
    salmon: 'https://www.salmonscotland.co.uk/',
    acores:'https://portal.azores.gov.pt/',
    i2s:'https://www.i2s.gr/',
    linkedin:'https://www.linkedin.com/company/nextocean-eo/about/',
    twitter:'https://twitter.com/nextocean_eo',
    requestAreaUrl:'https://service4eo.atlassian.net/servicedesk/customer/portal/9/group/44/create/133',
    youtube:'https://www.youtube.com/@nextoceanEO',
    amn: 'https://www.amn.pt/Paginas/Homepage.aspx',
    colabAtlantic: 'https://colabatlantic.com/'
  },
  cms: {
    url: 'https://cms4eo.services4eo.com',
    api: 'https://cms4eo.services4eo.com/api',
    website: 'nextocean',
  },
  downloadAllHelp:{
    url:'https://en.wikipedia.org/wiki/Comparison_of_download_managers'
  },
  geoportalUrl: 'https://nextocean-portal.services4eo.com/home',
  outputFilterPattern: '_PML_FMS__SHP',
  analyticsID: 'G-ZWXHQ4KQ69'
};
