import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export interface PaymentPayload{
  reference: string;
  method?: PaymentMethodSlug;
}

export interface TransactionPayload {
   id: number,
   description: string,
   transactionReference: string,
   status: string,
   amount: string,
   paymentMethod: PaymentMethod,
   createdAt: Date,
   updatedAt: Date,
}

export enum PaymentMethodSlug {
  PAYPAL = "PAYPAL",
}

export type PriceInfo = {
  basePrice: number;
  totalPrice: number;
  countryCode: string;
  vat?: VatInfo;
};

export type VatInfo = {
  abbreviation: string;
  amount: number;
  inclusive: boolean;
  rate: number;
}

export interface IPricePayload {
  serviceId: string;
  currency: string;
  toi?: toiPricePayload;
  aoi?: aoiPricePayload;
}

type toiPricePayload = {
  start: Date;
  stop: Date;
  unitOfMeasure: ToiMeasureUnits;
  price: number;
};

type aoiPricePayload = {
  wkt: string;
  unitOfMeasure: AoiMeasureUnits;
  price: number;
};

export type PaymentMethod = {
  slug: PaymentMethodSlug;
  title: string;
  icon: IconDefinition;
};

export enum AoiMeasureUnits {
  HA = 'HA',
  KM = 'KM',
  SQM = 'SQM',
}
export enum ToiMeasureUnits {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface MeasureUnits {
  area: AoiMeasureUnits;
  time: ToiMeasureUnits;
};

export interface UnitPrice {
  area: number;
  time: number;
  timeArea: number;
};

export type Balance = {
  balance: string;
};
export interface Account {
  address: string;
  balance?: string;
  estBalance?: string;
}

type GasPriceRange = { [key: string]: number };

export interface GasPricesResponse {
  fast: number;
  fastest: number;
  safeLow: number;
  average: number;
  block_time: number;
  blockNum: number;
  speed: number;
  safeLowWait: number;
  avgWait: number;
  fastWait: number;
  fastestWait: number;
  gasPriceRange: GasPriceRange;
}

export enum CurrencyCode {
  EUR = 'EUR',
  USD = 'USD',
}

export interface Currency {
  code: CurrencyCode;
  name: string;
}
